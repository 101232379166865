<template>
  <div class="about">
    <div class="about-head-wrapper">
      <div class="about-header">
        <Header :appMenuStatusUpdate="appMenuStatusUpdate" />
      </div>
      <AboutBanner
        :corporateVideoUrl="corporateVideoUrl"
        :aboutDescription="aboutDescription"
        :aboutBannerPrimaryImage="baseUrl + aboutBannerPrimaryImage"
        :aboutBannerSecondaryImage="baseUrl + aboutBannerSecondaryImage"
      />
      <div class="belief-card-container">
        <div class="belif-heading">Beliefs</div>
        <div class="belief-container">
          <div class="belief-card-container__cards" v-for="(belief, index) in this.belief" :key="index">
            <BeliefCard
              :desc="belief.field_belief_text"
              :heading="belief.field_belief_title"
              :imgUrl="baseUrl + belief.field_belief_image"
            />
          </div>
        </div>
      </div>
      <div class="leader-carousel">
        <div>
          <h1 class="leader-heading">Leaders</h1>
        </div>
        <div v-if="leaderDetails">
          <flickity ref="flickity" :options="flickityOptions">
            <div
              v-for="(item, index) in this.leader"
              class="carousel-cell"
              :key="index"
              @click="goToLeader(item.field_id)"
            >
              <LeaderCard
                :leaderName="item.field_leader_name"
                :leaderInfo="item.field_leader_designation"
                :leaderImage="baseUrl + item.field_leader_image"
              />
            </div>
          </flickity>
        </div>
        <div class="subscribe-bar">
          <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Flickity from 'vue-flickity'
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
import { Header, AboutBanner, Footer, BeliefCard, LeaderCard, SubscribeBar } from '@/components'
export default {
  name: 'About',
  components: {
    Footer,
    BeliefCard,
    LeaderCard,
    Flickity,
    AboutBanner,
    SubscribeBar,
    Header
  },
  data() {
    return {
      showSusbscribeBar: true,
      baseUrl: BACKEND_BASE_IMG_PATH,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        freeScroll: true,
        cellAlign: 'left'
      }
    }
  },
  methods: {
    ...mapActions('about', ['getLeader', 'getCorporateVideo', 'getBeliefCardDetails']),
    goToLeader(idLead) {
      this.$router.push({ name: 'leaderdetail', params: { idleads: idLead } })
    },
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  },
  computed: {
    ...mapState('about', ['detail', 'leader', 'corporateVideo', 'belief']),
    leaderDetails() {
      return !!this.leader.length
    },
    aboutBannerPrimaryImage() {
      return (this.corporateVideo && this.corporateVideo[0] && this.corporateVideo[0].field_primary_about_banner) || ''
    },
    aboutBannerSecondaryImage() {
      return (
        (this.corporateVideo && this.corporateVideo[0] && this.corporateVideo[0].field_secondary_about_banner) || ''
      )
    },
    aboutDescription() {
      return (this.corporateVideo && this.corporateVideo[0] && this.corporateVideo[0].field_about_description) || ''
    },
    corporateVideoUrl() {
      return (this.corporateVideo && this.corporateVideo[0] && this.corporateVideo[0].field_about_video1_url) || ''
    }
  },
  mounted() {
    this.getCorporateVideo()
    this.getLeader()
    this.getBeliefCardDetails()
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.about-header {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: $primary-white;
}
.about {
  margin-top: 100px;
  @include ipad-portrait {
    margin-top: 90px;
  }
  @include iphone-portrait {
    margin-top: 90px;
  }
  .about-head-wrapper {
    min-height: calc(80vh);
  }
}
.belif-heading {
  object-fit: contain;
  font-family: GothamMedium;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #202124;
  margin-bottom: 30px;
}
.belief-card-container {
  margin: 70px 80px 0 80px;
  .belief-container {
    display: flex !important;
    justify-content: space-between;
    @include ipad-default-portrait {
      align-items: center;
    }
    @include ipad-10-portrait {
      align-items: center;
    }
    @include ipad-portrait {
      align-items: center;
    }
  }
}
.carousel-cell {
  width: 35%;
  padding-left: 65px;
}
.leader-carousel {
  margin-top: 80px;
  margin-bottom: 170px;
  .leader-heading {
    color: $heading-black;
    margin: 70px 0 30px 80px;
    font-weight: 500;
    font-family: GothamMedium;
    font-size: 32px;
  }
}
@include iphone-portrait {
  .carousel-cell {
    width: 75%;
    padding-left: 0 !important;
    padding-right: 0;
  }
  .belief-container {
    flex-direction: column;
  }
  .leader-carousel {
    margin: 0;
    ::v-deep .flickity-slider {
      margin-left: 15px !important;
    }
  }
  .belief-card-container {
    margin: 30px 15px 30px 15px !important;
  }
  .leader-heading {
    margin: 50px 0 30px 15px !important;
  }
}
@include ipad-landscape {
  .belief-card-container {
    margin: 60px 80px 130px 80px;
    .belif-heading {
      margin-bottom: 30px;
    }
  }
  .carousel-cell {
    padding-left: 50px;
  }
  .leader-carousel {
    margin: 0;
    ::v-deep .flickity-slider {
      margin-left: -30px !important;
    }
  }
}
@include larger-screens {
  .carousel-cell {
    width: 25%;
  }
}
@include ipad-portrait {
  .belief-container {
    flex-direction: column;
  }
  .carousel-cell {
    width: 35% !important;
  }
  .belief-card-container {
    margin: 60px 51px 0 50px !important;
  }
  .leader-carousel {
    margin-bottom: 70px;
  }
  .leader-heading {
    margin: 70px 0 30px 50px !important;
  }
  .is-selected {
    left: 491%;
  }
}
::v-deep .flickity-prev-next-button {
  @include iphone-portrait {
    display: none;
  }
  @include ipad-portrait {
    display: block;
  }
}
::v-deep .flickity-button-icon {
  fill: $dim-gray;
}
::v-deep .flickity-prev-next-button .flickity-button-icon {
  width: 70% !important;
  height: 70% !important;
}
::v-deep .leader-image {
  width: auto !important;
}
</style>
